<template>
<div class="main">
	<h1>Oops!</h1>
	<p>We apologize, but our server thinks you're a bot.</p>
	<p>If you're a real person, please press and hold this button...</p>
	<div class="button" @mousedown="mouseDown()" @touchstart="mouseDown()" @mouseup="clearMouseDown()" @touchend="clearMouseDown()">I'm Not A Bot</div>
	<div class="countdown">
		<div class="countdown-bar" ref="countdown_bar"></div>
	</div>
</div>
</template>

<script>
export default {
	name: 'not_bot',
	data() {
		return {
			timer: null,
			interval: null
		}
	},
	methods: {
		mouseDown() {
			let min = 1500;
			let max = 4000;
			let time = Math.floor(Math.random() * (max - min + 1)) + min;
			let countDownTime = 1
			// set an interval for time seconds
			this.interval = setInterval(() => {
				// update the countdown bar
				this.$refs.countdown_bar.style.width = ((countDownTime / time) * 100) + '%'
				if (countDownTime >= time) {
					clearInterval(this.interval)
				}
				countDownTime += 4 // no idea why we increment by four!
			}, 1)
			// set a timer for time seconds
			this.timer = setTimeout(() => {
				// if the timer runs out, redirect to the home page
				document.location.replace((document.location.hostname.indexOf('.test') > -1 ? '/frontend' : '') + '/')
			}, time)
		},
		clearMouseDown() {
			// clear the timer
			clearTimeout(this.timer)
			clearInterval(this.interval)
			this.$refs.countdown_bar.style.width = '0%'
		}
	}
}
</script>

<style lang="scss" scoped>
.main {
	text-align: center;
	font-family: Helvetica, Arial, sans-serif;
	.button {
		display: inline-block;
		padding: 10px 20px;
		background-color: #000;
		color: #fff;
		border-radius: 5px;
		cursor: pointer;
		&:hover {
			background-color: #333;
		}
		&:active {
			background-color: #666;
		}
	}
	.countdown {
		position: relative;
		overflow: hidden;
		width: 200px;
		height: 24px;
		margin: 20px auto;
		background-color: #fff;
		border-radius: 5px;
		border: 1px solid #999;
	}
	.countdown-bar {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		background-color: #666;
	}
}
</style>
